class Top {
    constructor() {
        let that = this;
        let html = `
            <div id="mainContainer" class="main-container d-flex flex-column align-items-center justify-content-around">
                <div class="d-flex flex-column align-items-center" style="width: 700px; height: 400px;">
                    <div style="height: 25%; font-size: 1.6rem;">地震速報システム</div>
                    <div class="border d-flex flex-column" style="width: 400px; height: 75%;">
                        <div class="d-inline-flex" style="height: 20%;">&nbsp;</div>
                        <div class="d-inline-flex" style="height: 15%;">
                            <div class="d-flex align-items-center h-100 justify-content-end" style="width: 40%; padding-right: 10px;">ログインID：</div>
                            <div class="d-flex align-items-center h-100" style="width: 60%;">
                                <input type="text" id="txtLoginId" value="">
                            </div>
                        </div>
                        <div class="d-inline-flex" style="height: 15%;">
                            <div class="d-flex align-items-center h-100 justify-content-end" style="width: 40%; padding-right: 10px;">パスワード：</div>
                            <div class="d-flex align-items-center h-100" style="width: 60%;">
                                <input type="password" id="txtPassword" value="">
                            </div>
                        </div>
                        <div class="d-inline-flex" style="height: 15%;">&nbsp;</div>
                        <div class="d-inline-flex align-items-center justify-content-around" style="height: 35%;">
                            <input type="button" id="btnReset" style="width: 100px; height: 28px;" value="リセット">
                            <input type="button" id="btnLogin" style="width: 100px; height: 28px;" value="ログイン">
                        </div>
                    </div>
                </div>
            </div>`;
        $('body').html(html);

        $('body').on('click', '#btnLogin', () => {
            that.login();
        });

        $('body').on('click', '#btnReset', () => {
            that.resetLoginForm();
        });
    }

    login() {
        let loginId = $('#txtLoginId').val();
        let password = $('#txtPassword').val();
        $.ajax({
            type: 'POST',
            url: 'pd',
            data: {
                sid: "login",
                param: JSON.stringify({
                    username: loginId,
                    password: password
                })
            },
            success: function (data) {
                if(data != null && data != "" && data != undefined){
                    let rtnObj = JSON.parse(data);
                    if(rtnObj.error) new global.Message().alertOnlyMsg("Error", rtnObj.error);
                    else if(rtnObj.success) $.redirect("earthquake.html");
                }
            },
            error: function(data) {
                new global.Message().alertTypeMsg("Error", "Error", data);
            }
        });
    }

    resetLoginForm() {
        $('#txtLoginId').val('');
        $('#txtPassword').val('');
    }
}

export { Top }
