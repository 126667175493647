class Message {
    constructor() {
    }

    alertTypeMsg(type, title, message, callback) {
        $("#msg-win").remove();
        let msg = typeof(message) === Object ? JSON.stringify(message) : message;
        
        let document = window.document;
        let body = document.body;
        let div = document.createElement('div')
        div.id = 'msg-win';
        div.title = title;
        div.innerHTML = 
            '<div class="m-2">' +
                '<div style="display:inline-block;">' + type + ':&nbsp&nbsp</div>'+
                '<div style="display:inline-block;">' + msg + '</div>' +
            '</div>';
        body.appendChild(div);

        $("#msg-win").dialog({
            minWidth: 500,
            closeOnEscape: false,
            open: function() {
                $('#msg-win').parent().find('.ui-dialog-titlebar-close').remove();
                if(callback) callback(this);
            },
            buttons: [{
                text: "close",
                click: function() {
                    $("#msg-win").remove();
                }
            }]
        });
    }

    alertOnlyMsg(title, message, callback) {
        $("#msg-win").remove();
        let msg = typeof(message) === Object ? JSON.stringify(message) : message;
        
        let document = window.document;
        let body = document.body;
        let div = document.createElement('div')
        div.id = 'msg-win';
        div.title = title;
        div.innerHTML = 
            '<div class="m-2">' +
                '<div style="display:inline-block;">' + msg + '</div>' +
            '</div>';
        body.appendChild(div);

        $("#msg-win").dialog({
            minWidth: 500,
            closeOnEscape: false,
            open: function() {
                $('#msg-win').parent().find('.ui-dialog-titlebar-close').remove();
                if(callback) callback(this);
            },
            buttons: [{
                text: "close",
                click: function() {
                    $("#msg-win").remove();
                }
            }]
        });
    }
}

export { Message }