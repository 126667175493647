// import "../../node_modules/bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.css";
import "../css/earthquake.css";
import "./lib/jquery-ui-1.13.2/jquery-ui.css";

import { Top }                  from './component/Top';
import { Message }              from './component/Message';


global.Message                  = Message;

new Top();

if (window.history.replaceState) window.history.replaceState(null, null, window.location.href);

export {};



